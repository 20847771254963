/* tslint:disable */
import { Injectable, ViewChild } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {


  // stegging
  baseUrl2 = "https://hubadminapi.aoc.bigcityvoucher.co.in/v1"
  // baseUrl2 = "https://hubadminapi.jgi.bigcityvoucher.co.in/v1";
  // baseUrl2 = "https://hubadmin.ub.bigcityvoucher.co.in/v1";

  // production
  // baseUrl2 = "https://hubadminapi.hamrojgi.com/v1";



  login = this.baseUrl2 + "/hubadmin/log-in";
  // login = this.baseUrl2 + "/users/hub-login";
  getDashBoardDetails = this.baseUrl2 + "/adminreport/generalreports?program_id=1&"
  agentDashboard = this.baseUrl2 + "/visibility/dashboard";
  // HUBADMIN
  getStates = this.baseUrl2 + "/users/state";
  getRegion = this.baseUrl2 + "/users/region";
  getCity = this.baseUrl2 + "/users/get-city"
  getPortfolio = this.baseUrl2 + "/users/get-portfolio"
  getPointCalc = this.baseUrl2 + "/users/get-target-run"
  updatePointCalc = this.baseUrl2 + "/users/target-run-insert"
  getSeMapping = this.baseUrl2 + "/users/get-executive-mapping"
  getRhMapping = this.baseUrl2 + "/users/get-rm-mapping"
  //  USER MANAGEMENT
  getOutletOwner = this.baseUrl2 + "/users/get-outlet-user";
  getSalesExcutive = this.baseUrl2 + "/users/get-se-user";
  getRH = this.baseUrl2 + "/users/get-rm-user"
  getASM = this.baseUrl2 + "/users/get-asm-user";
  outletOnboard = this.baseUrl2 + "/users/user-import"
  addManager = this.baseUrl2 + "/users/insert-manager"
  updateManager = this.baseUrl2 + "/users/update-manager"
  addRegionalHead = this.baseUrl2 + "/users/insert-regional-manager"
  updateRegionalHead = this.baseUrl2 + "/users/update-regional-manager"
  getChannels = this.baseUrl2 + "/users/get-channel";
  getMappingExecutive = this.baseUrl2 + "/users/get-manager-mapping"
  getmappingmanager = this.baseUrl2 + "/users/get-rm-mapping"
  getmappingrh = this.baseUrl2 + "/users/get-nm-mapping"
  getmappingOutletOwner = this.baseUrl2 + "/users/get-executive-mapping"
  addSalesExicutive = this.baseUrl2 + "/users/insert-executive"
  updateSalesExicutive = this.baseUrl2 + "/users/update-executive"
  addOutletOwner = this.baseUrl2 + "/users/insert-outlet-master"
  updateOutletOwner = this.baseUrl2 + "/users/outlet-update"
  // REWARDS
  uploadRewardsCategories = this.baseUrl2 + "/rewards/import-reward-category"
  uploadRewardsSubCategories = this.baseUrl2 + "/rewards/import-reward-sub-category"
  deleteRewardProducts = this.baseUrl2 + "/rewards/delete-reward-products"
  getRoles = this.baseUrl2 + "/user-role/get-user-roles"
  get_reward_brand = this.baseUrl2 + "/rewards/get-reward-brands"
  uploadRewardBrands = this.baseUrl2 + "/rewards/import-reward-brands?"
  create_reward_brand = this.baseUrl2 + '/rewards/create-reward-brands';
  update_reward_brand = this.baseUrl2 + '/rewards/update-reward-brands';
  delete_reward_brand = this.baseUrl2 + '/rewards/delete-reward-brands';
  //  Targets & Achivements

  // AGENTPANEL
  //BEERS
  beersDashboard = this.baseUrl2 + '/visibility/beer-task-details'
  spiritDashboard = this.baseUrl2 + '/visibility/spirts-task-details'
  beersOotherImageTask = this.baseUrl2 + "/visibility/other-image-task-beers"
  updateBeersOtherImageTask = this.baseUrl2 + "/visibility/other-image-task-verification-beers"
  getPosmBeers = this.baseUrl2 + "/visibility/posm-beers"
  getBrandingBeers = this.baseUrl2 + "/visibility/branding-beers"
  updateBrandingBeers = this.baseUrl2 + "/visibility/branding-verification-beers"
  updateBeersPosmTask = this.baseUrl2 + "/visibility/posm-verification-beers"
  chillerVisibilityBeers = this.baseUrl2 + "/visibility/chiller-visibility-beers";
  updateChillarVisibilityBeers = this.baseUrl2 + "/visibility/chiller-verification-beers"
  warmStackBeers = this.baseUrl2 + "/visibility/warm-stock-visibility-beers"
  updateWarmStackBeers = this.baseUrl2 + "/visibility/warm-stock-verification-beers"
  shelfBeers = this.baseUrl2 + "/visibility/shelf-visibility-beers"
  updateShelfBeers = this.baseUrl2 + "/visibility/shelf-verification-beers"

  // Spirit
  shelfVisibility = this.baseUrl2 + "/visibility/shelf-visibility-sprits";
  spiritOtherImageTask = this.baseUrl2 + "/visibility/other-image-task-sprits"
  updateSpiritImageTask = this.baseUrl2 + "/visibility/other-image-task-verification-spirts"
  getPosmSpirit = this.baseUrl2 + "/visibility/posm-spirts"
  getBrandingSpirits = this.baseUrl2 + "/visibility/branding-spirts"
  getPosmSpirits = this.baseUrl2 + "/visibility/posm-spirts"
  getOtherImageSpirits = this.baseUrl2 + "/visibility/other-image-task-sprits"
  editBrandingSpirits = this.baseUrl2 + "/visibility/branding-verification-sprits"
  editShelfSpirits = this.baseUrl2 + "/visibility/shelf-verification-sprits"
  editPosmSpirits = this.baseUrl2 + "/visibility/posm-verification-sprits"
  warmStockSpirit = this.baseUrl2 + "/visibility/warm-stock-visibility-sprits"
  updateWarmStockSpirit = this.baseUrl2 + "/visibility/warm-stock-verification-sprits"
  chillerSpirit = this.baseUrl2 + "/visibility/chiller-visibility-sprits"
  updateChillerSpirit = this.baseUrl2 + "/visibility/chiller-verification-sprits"




  //***MASTERDATA***/
  // Region
  configGetRegion = this.baseUrl2 + "/users/get-region";
  targetruninsert = this.baseUrl2 + "/loyalty-target/targetruninsert";
  configAddRegion = this.baseUrl2 + "/users/create-region";
  configUpdateRegion = this.baseUrl2 + "/users/update-region";
  configDeleteRegion = this.baseUrl2 + "/users/delete-region";
  importExcelRegion = this.baseUrl2 + "/users/import-region";
  //states
  configGetStates = this.baseUrl2 + "/users/get-state";
  configGetStatesAsc = this.baseUrl2 + "/state/get-state-asc";
  configAddState = this.baseUrl2 + "/users/create-state";
  configEditState = this.baseUrl2 + "/users/update-state";
  configDeleteState = this.baseUrl2 + "/users/delete-state";
  importExcelState = this.baseUrl2 + "/users/import-state";
  //Cities
  configGetCities = this.baseUrl2 + "/users/get-city";
  configAddCity = this.baseUrl2 + "/users/create-city";
  configEditCity = this.baseUrl2 + "/users/update-city";
  configDeleteCity = this.baseUrl2 + "/users/delete-city";
  importExcelCity = this.baseUrl2 + "/users/import-city";
  //Brands
  configGetBrands = this.baseUrl2 + "/hubadmin/get-brands";
  configAddBrand = this.baseUrl2 + "/hubadmin/create-brand";
  configEditBrand = this.baseUrl2 + "/hubadmin/update-brand";
  configDeleteBrand = this.baseUrl2 + "/brands/delete-brand";
  //Channels
  configGetChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";
  configAddChannel = this.baseUrl2 + "/channels/create-channel";
  configEditChannel = this.baseUrl2 + "/channels/update-channel";
  configDeleteChannel = this.baseUrl2 + "/channels/delete-channel";
  // Languages
  configGetLanguages = this.baseUrl2 + "/users/get-languages";
  configAddLanguage = this.baseUrl2 + "/users/addlanguage";
  configEditLanguage = this.baseUrl2 + "/users/update-language";
  configDeleteLanguage = this.baseUrl2 + "/channels/delete-channel";
  // Frequency
  configGetFrequency = this.baseUrl2 + "/frequency/get-frequency";
  configAddFrequency = this.baseUrl2 + "/frequency/create-frequency";
  configEditFrequency = this.baseUrl2 + "/frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl2 + "/units/get-units";
  configAddUnit = this.baseUrl2 + "/units/create-unit";
  configEditUnit = this.baseUrl2 + "/units/update-unit?id=";
  // Brand Categories

  //ROLES
  configGetRoles = this.baseUrl2 + "/user-role/get-user-roles";
  configAddRoles = this.baseUrl2 + "/user-role/add-user-roles";
  configUpdateRoles = this.baseUrl2 + "/user-role/update-user-role"; //same for delete
  //***USER MANAGEMENT***/
  getUsers = this.baseUrl2 + "/users/getuser";
  allProfiles = this.baseUrl2 + "/user-role/get-user-roles";
  createUserPost = this.baseUrl2 + "/users/create-user";
  updateUserPost = this.baseUrl2 + "/users/update-user?id=";
  importExcelUser = this.baseUrl2 + "/users/import-users";
  importNewUsers = this.baseUrl2 + "/users/insert-users";

  getUserById = this.baseUrl2 + "/users/get-users?id=";
  //Filter data, regions,channel,roles, geography
  filterDataParams = this.baseUrl2 + "/course/get-course?program_id=1";
  //GET OUTLETS MAPPED TO A TSE
  getMappedOutlets = this.baseUrl2 + "/visit-tracker/get-tse-outlets";
  //visit tracker
  getCampaingsBasedOnFilter = this.baseUrl2 + "/campaign/search-campaigns";
  getTasksBasedOnCampaign =
    this.baseUrl2 + "/visit-tracker/visit-tracker-tasks?campaign_id=";
  getVtTseList = this.baseUrl2 + "/visit-tracker/visit-tracker-tse?task_id=";
  //**Channel**//
  getAllChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";

  //***REWARDS***//
  //reward category
  getRewardCategories = this.baseUrl2 + "/rewards/get-reward-category?program_id=1";
  addRewardCategories = this.baseUrl2 + "/rewards/create-reward-category";
  editRewardCategories = this.baseUrl2 + "/rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl2 + "/rewards/delete-reward-category";
  rewardHistory = this.baseUrl2 + "/rewards/get-reward-orders?program_id=1";
  getRewardProducts = this.baseUrl2 + "/rewards/get-reward-products-filter?program_id=1";
  getRewardProductbyId =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl2 + "/rewards/create-reward-products";
  updateRewardProducts = this.baseUrl2 + "/rewards/update-reward-products";
  getRewardSubCategory =
    this.baseUrl2 +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands = this.baseUrl2 + "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";

  //reward sub category
  getRewardSubCategories = this.baseUrl2 + "/rewards/get-reward-subcategory?program_id=1";
  addRewardSubCategories = this.baseUrl2 + "/rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl2 + "/rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl2 + "/rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl2 + "/rewards/get-reward-type";
  addRewardType = this.baseUrl2 + "/rewards/create-reward-type?id=1";
  editRewardType = this.baseUrl2 + "/rewards/update-reward-type?id=";
  deleteRewardType = this.baseUrl2 + "/rewards/update-reward-type?id=";
  // Engagement API
  engagementCreateActivity = this.baseUrl2 + "/engagement/create-campaign-activity";
  engagementUpdate = this.baseUrl2 + "/engagement/update-task?id=";
  getAllEngagement = this.baseUrl2 + "/engagement/get-engagements";
  getEngagementById = this.baseUrl2 + "/engagement/get-engagements?id=";
  getEngagementTemplateById = this.baseUrl2 + "/engagement/get-picture-template?task_id=";
  createEngagementTask = this.baseUrl2 + "/engagement/create-task";
  getUserAssignment = this.baseUrl2 + "/user-modules/get-all-whitelisted-user";
  getUsersFilter = this.baseUrl2 + "/users/search-users-by-filter";
  getQuizDetails = this.baseUrl2 + "/engagement/get-quiz-activities?";
  assignUserDetails = this.baseUrl2 + "/engagement/assign-user-task";
  createQuizEngage = this.baseUrl2 + "/engagement/create-quiz";
  updateQuizEngage = this.baseUrl2 + "/engagement/update-quiz?id=";
  createpointsConfig =
    this.baseUrl2 + "/engagement/create-engagement-point-structure";
  getEngagementTaskPoints =
    this.baseUrl2 + "/engagement/engagement-point-structure?task_id=";
  getAssignedUsers = this.baseUrl2 + "/engagement/get-user-tasks?task_id=";
  uploadImages = this.baseUrl2 + "/engagement/upload-img";
  setQuizCreationCompleteForcampaign =
    this.baseUrl2 + "/engagement/set-quiz-creation-complete-forcampaign";
  /**
   * Activity
   */
  campaign_activites = this.baseUrl2 + "/engagement/get-campaign-activites";
  createActivity = this.baseUrl2 + "/engagement/create-campaign-activity";
  updateActivity = this.baseUrl2 + "/engagement/update-campaign-activity?id=";
  getActivityBasedOnTask =
    this.baseUrl2 + "/engagement/get-campaign-activites?campaign_id=";
  //orders
  getAllOrders = this.baseUrl2 + "/ecommerce/get-ecommerce-orders";
  getOrderDetails = this.baseUrl2 + "/ecommerce/view-ecommerce-order?id=";
  orderApproveReject = this.baseUrl2 + "/ecommerce/accept-reject-order";
  getAllCategiry = this.baseUrl2 + "/brand-category/get-brand-categories";
  createCatgory = this.baseUrl2 + "/ecommerce/create-ecommerce-categories";
  updateCategory = this.baseUrl2 + "/ecommerce/update-ecommerce-categories";

  //***REWARDS***//
  getAllOffers =
    this.baseUrl2 + "/ecommerce/loaddata-for-offer-details?program_id=1";
  //Schemes
  getSchemes = this.baseUrl2 + "/scheme/get-scheme";
  addSchemes = this.baseUrl2 + "/scheme/create-scheme";
  getSchemeConfig = this.baseUrl2 + "/scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl2 + "/scheme/get-brand-category";
  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl2 + "/visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl2 + "/visibility/create-posm-elements";
  editVisibility = this.baseUrl2 + "/visibility/update-posm-elements";
  deleteVisibility = this.baseUrl2 + "/visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl2 + "/visibility/create-posm-config";
  getVisibilityConfig = this.baseUrl2 + "/visibility/get-posm-config";
  updateVisibilityConfig = this.baseUrl2 + "/visibility/update-posm-config";
  updateVisiblityUserUpload = this.baseUrl2 + "/visibility/update-visibility-user-upload";
  getVisibilitySubmittedList =
    this.baseUrl2 + "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl2 + "/visibility/posm-audit-approve-reject";
  getPosmOnChannel =
    this.baseUrl2 +
    "/visibility/get-channel-posm-elements?program_id=1&channel_id=";
  getChillerSelection =
    this.baseUrl2 + "/visibility/get-chiller-pureimpure-selection";
  chillerSelectionCreate =
    this.baseUrl2 + "/visibility/chiller-pureimpure-selection-create";
  chillerSelectionUpdate =
    this.baseUrl2 + "/visibility/chiller-pureimpure-selection-update";
  getChillerRegionScheme =
    this.baseUrl2 + "/visibility/get-chiller-regionwise-scheme";
  chillerRegionSchemeCreate =
    this.baseUrl2 + "/visibility/chiller-regionwise-scheme-create";
  chillerRegionSchemeUpdate =
    this.baseUrl2 + "/visibility/chiller-regionwise-scheme-update";
  chillerSchemeUpdate =
    this.baseUrl2 + "/visibility/update-chiller-scheme";
  getPosmAuditTypes = this.baseUrl2 + "/visibility/get-posm-audit-types";
  updatePosmAuditTypes = this.baseUrl2 + "/visibility/update-posm-audit-types";
  createPosmAuditTypes = this.baseUrl2 + "/visibility/create-posm-audit-types";
  coolerRejectionReason = this.baseUrl2 + "/cooler-rejection-reason/get-cooler-rejection-reason";
  coolerRejectionReasonPost = this.baseUrl2 + "/cooler-rejection-reason/create-cooler-rejection";

  //campaign
  getAllCampaign = this.baseUrl2 + "/campaign/get-campaigns";
  addCampaign = this.baseUrl2 + "/campaign/create-campaign";
  editCampaign = this.baseUrl2 + "/campaign/update-campaign";
  disableUserFromCampaign = this.baseUrl2 + "/userdetails/user-disable"
  //* GEOGRAPHY *//
  getAllGeographies = this.baseUrl2 + "/geographical/get-geographs";
  addGeography = this.baseUrl2 + "/geographical/create-geography";
  editGeography = this.baseUrl2 + "/geographical/update-geography?id=";
  //ecommerce
  getProductsList = this.baseUrl2 + "/brands/get-products";
  getProductById =
    this.baseUrl2 + "/product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl2 + "/product/deleteproduct";
  addToProductList = this.baseUrl2 + "/product/addnewproduts";
  getProductCategories =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl2 + "/brands/update-product";
  createGeography = this.baseUrl2 + "/geographical/create-geography";
  getProductVarient = this.baseUrl2 + "/product/get-product-variants";
  getProductVarientValues =
    this.baseUrl2 + "/product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl2 + "/ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl2 + "/ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl2 +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";
  //****carton Insert****//
  getCartonList = this.baseUrl2 + "/tertiary-programs/get-cartons?program_id=1";
  getCartonCodes =
    this.baseUrl2 +
    "/tertiary-programs/get-cartons-codes?program_id=1&carton_id=";
  createCarton = this.baseUrl2 + "/tertiary-programs/create-cartons";
  generateQRImages =
    this.baseUrl2 + "/tertiary-programs/qr-code-generation-api";
  /**
   * Content & learning
   */
  getContenentAndLearning =
    this.baseUrl2 + "/content-learning/get-course?program_id=1&course_id=";
  getTaskTypes = this.baseUrl2 + "/content-learning/course-type";
  courseCreation = this.baseUrl2 + "/content-learning/create-course";
  courseUserAssign = this.baseUrl2 + "/content-learning/map-course-users";
  updateContentAndLearning = this.baseUrl2 + "/content-learning/update-course";
  deleteContentAndLearning = this.baseUrl2 + "/content-learning/delete-course";
  createCourseQuiz = this.baseUrl2 + "/content-learning/create-quiz";
  updateCourseQuiz = this.baseUrl2 + "/content-learning/update-quiz";
  deleteCourseQuiz = this.baseUrl2 + "/content-learning/delete-quiz";
  getAllCourseQuiz =
    this.baseUrl2 + "/content-learning/get-quiz?program_id=1&course_id=";
  getContentAndLearningMappedUser =
    this.baseUrl2 + "/content-learning/mapped-course-user-lists";
  getContentAndLearningUserTransaction =
    this.baseUrl2 + "/content-learning/course-user-transaction";

  /**
   * Visit tracker
   */
  getVisits = this.baseUrl2 + "/visit-tracker/get-visits?program_id=1";
  createVisits = this.baseUrl2 + "/visit-tracker/create-visits";
  getVisitEnrollment =
    this.baseUrl2 + "/visit-tracker/get-enrollments?program_id=1";
  approveRejectEnroll =
    this.baseUrl2 + "/visit-tracker/approve-reject-enrollment";
  saveScheme = this.baseUrl2 + "/scheme/create-scheme-data";
  createEnroll = this.baseUrl2 + "/visit-tracker/create-enrollment";
  getOfferById = this.baseUrl2 + "/ecommerce/edit-offers?program_id=1&";

  /****/
  // getTargetList = this.baseUrl2 + "/loyalty-target/get-targetachv-data";
  // getTargetAchivementList = this.baseUrl2 + "/loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl2 + "/loyalty-target/update-target";
  addTargetAchivement = this.baseUrl2 + "/loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  createOffer = this.baseUrl2 + "/ecommerce/create-offers";
  deleteOffer = this.baseUrl2 + "/ecommerce/delete-offers";
  updateOffer = this.baseUrl2 + "/ecommerce/update-offers";
  createWhitelist = this.baseUrl2 + "/user-modules/create-user-module";
  updateWhitelist = this.baseUrl2 + "/user-modules/import-update-user-module";
  getModule = this.baseUrl2 + "/module/get-modules";
  getUserModules = this.baseUrl2 + "/user-modules/get-user-modules";
  getAllUserModules = this.baseUrl2 + "/user-modules/usermoduleextract";
  updateUserModules = this.baseUrl2 + "/user-modules/update-user-module";
  createSelectedUserModules = this.baseUrl2 + "/user-modules/create-selected-user-module";
  createLoyalty = this.baseUrl2 + "/loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl2 + "/loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  getPointStructure = this.baseUrl2 + "/points-structure/get-points-structure";
  updateLoyaltyTarget = this.baseUrl2 + "/loyalty-target/update-loyalty-target";
  updateLoyaltyProductTarget = this.baseUrl2 + "/loyalty-target/update-product-wise-target";
  updatePointsTarget =
    this.baseUrl2 + "/points-structure/update-points-structure";
  updatePointsProductTarget =
    this.baseUrl2 + "/points-structure/update-product-wise-points";
  /**Excel section */
  uploadMultiProducts = this.baseUrl2 + "/product/upload-products";
  uploadMultiRewards = this.baseUrl2 + "/rewards/create-reward-products-excel";
  createSalesAchivement = this.baseUrl2 + "/loyalty-target/create-achievements";
  getSalesAchivements =
    this.baseUrl2 + "/loyalty-target/get-loyalty-achievements";
  getLoyaltyNotIn = this.baseUrl2 + "/loyalty-target/get-unique-loyalty-achievements";
  updateSalesAchievement =
    this.baseUrl2 + "/loyalty-target/update-loyalty-achievement";
  approveSalesAchievement =
    this.baseUrl2 + "/loyalty-target/approve-sales-achievements";
  filterByCity = this.baseUrl2 + "/city/filter-city";
  productUploadExcelFields = this.baseUrl2 + "/product/product-upload-fields";
  getReardProductsWithPagination =
    this.baseUrl2 + "/rewards/get-reward-products-filter?program_id=1";
  // USER POINTS

  getUserPoints = this.baseUrl2 + "/users/get-user-points";
  createUserPoints = this.baseUrl2 + "/users/create-user-point";
  uploadUserPoints = this.baseUrl2 + "/users/upload-user-point";
  uploadUserAssignment = this.baseUrl2 + "/engagement/upload-engagement-users";
  updateUserAssignment = this.baseUrl2 + "/engagement/update-assign-user-task";
  uploadUserAssignmentCL = this.baseUrl2 + "/content-learning/upload-content-learning-users";
  updateUserCLAssignment = this.baseUrl2 + "/content-learning/update-assign-user-task";
  getGeoGroups = this.baseUrl2 + "/users/get-geo-group";
  updateGeoGroups = this.baseUrl2 + "/users/update-geo-group";
  createGeoGroups = this.baseUrl2 + "/users/create-geo-group"
  getGeoUser = this.baseUrl2 + "/users/get-geo-group-users";
  updateGeoUser = this.baseUrl2 + "/users/update-geo-group-users";
  BulkLoadGeoUser = this.baseUrl2 + "/users/upload-geo-group-users";
  UpdateUserMobile = this.baseUrl2 + "/users/update-user-mobile";
  getEngagementImagesURL = this.baseUrl2 + "/engagement/get-engagement-images";
  uploadVisiblityUsers = this.baseUrl2 + "/visibility/visibility-user-upload";
  getUploadVisiblityUsers = this.baseUrl2 + "/visibility/get-visibility-user-upload";
  CreateSchemenUploadVisiblityUsers = this.baseUrl2 + "/visibility/create-chiller-bonus-point-scheme";
  getUploadSchemeVisiblityUsers = this.baseUrl2 + "/visibility/get-chiller-bonus-assigned-users";
  UpdateUploadSchemeVisiblityUsers = this.baseUrl2 + "/visibility/update-chiller-bonus-assigned-users";
  getChillerSchemes = this.baseUrl2 + "/visibility/get-chiller-bonus-point-scheme";
  updateSchemenUploadVisiblityUsers = this.baseUrl2 + "/visibility/update-chiller-bonus-point-scheme";
  getAllUsers = this.baseUrl2 + "/users/get-supervisors";
  allUsers = this.baseUrl2;



  /**
   * Reports
   */
  getAccountLoginReport = this.baseUrl2 + "/adminreport/downloadhubadminreport";
  getPointsaccountstatement = this.baseUrl2 + "/adminreport/downloadhubadminreport";
  getEngagementstatusdetails = this.baseUrl2 + "/adminreport/engagementstatusdetails";
  getPointAcS = this.baseUrl2 + "/adminreport/pointsaccountstatement";

  getPointExipryRule = this.baseUrl2 + "/points-exipry-rule/get-point-exipry-rules";
  createPointExipryRule = this.baseUrl2 + "/points-exipry-rule/create-point-exipry-rule";
  updatePointExipryRule = this.baseUrl2 + "/points-exipry-rule/update-point-exipry-rule";

  submitGapAnalysis = this.baseUrl2 + "/users/outletgapanalysis";
  gapAnalysisNew = this.baseUrl2 + "/users/gapanalysis"
  gapAnalysisReportnew = this.baseUrl2 + "/users/gapanalysisreport"

  // Menu Programs

  getMenuProgramEmail = this.baseUrl2 + "/menu/get-menu-spocemail-config";
  createMenuProgramEmail = this.baseUrl2 + "/menu/create-menu-spocemail-config";
  updateMenuProgramEmail = this.baseUrl2 + "/menu/update-menu-spocemail-config";

  // Mystery Shopper

  importMysteryExcelUser = this.baseUrl2 + "/mystery-shopper/import-users";
  getAllMysteryCampaign = this.baseUrl2 + "/mystery-shopper/get-mystery-campagin";
  createMysteryCampaign = this.baseUrl2 + "/mystery-shopper/create-mystery-campagin";
  updateMysteryCampaign = this.baseUrl2 + "/mystery-shopper/update-mystery-campagin";
  whiteOutlistMysteryCampaign = this.baseUrl2 + "/mystery-shopper/get-mystery-audit-users";
  updateWhiteOutlistMysteryCampaign = this.baseUrl2 + "/mystery-shopper/update-whitelist-users-date-range";
  BulkLoadMysteryUser = this.baseUrl2 + "/mystery-shopper/mystry-shopper-outlet-whitelist";
  getAllMysteryQuestionBank = this.baseUrl2 + "/mystery-shopper/get-mystery-question-bank";
  updateMysteryQuestionBank = this.baseUrl2 + "/mystery-shopper/update-mystery-question-bank";
  importQuestionBankExcel = this.baseUrl2 + "/mystery-shopper/import-question-bank";
  getMysteryCampaignOutlets = this.baseUrl2 + "/mystery-shopper/get-mystery-campaign-outlets";
  editMysteryAssignment = this.baseUrl2 + "/mystery-shopper/editmysterycampaignoutlets";
  bulkAssignOutlets = this.baseUrl2 + "/mystery-shopper/assign-users";
  ImportAssignOutlets = this.baseUrl2 + "/mystery-shopper/import-assign-users";
  getAllmysteryTemplate = this.baseUrl2 + "/mystery-shopper/get-mystery-template";
  updatemysteryTemplate = this.baseUrl2 + "/mystery-shopper/update-mystery-template";
  createMysteryTemplate = this.baseUrl2 + "/mystery-shopper/create-mystery-template";
  getAllTemplateAssignedUsers = this.baseUrl2 + "/mystery-shopper/get-mystery-template-users";
  BulkLoadMysteryUserTemplate = this.baseUrl2 + "/mystery-shopper/import-mystry-shopper-template-outlets";

  //Crown Collection

  getCrownTargetList = this.baseUrl2 + "/crown-target/get-crown-targets";
  updateCrownTarget = this.baseUrl2 + "/crown-target/update-crown-target";
  addCrownTarget = this.baseUrl2 + "/crown-target/create-crown-target";
  uploadCrownPaymentReport = this.baseUrl2 + "/crown/update-payment-status"

  // App Ticketing API

  appTicket = "/ticket/tickettypelist?program_id=1";
  createAppTicket = "/ticket/tickettypeinsert";
  ticketById = "/ticket/ticketfieldlist?program_id=1&";
  createTicketData = "/ticket/ticketfieldinsert";

  //App Schedular
  getSchedular = this.baseUrl2 + "/batchscheduler/batchlist?program_id=1"
  sendSchedular = this.baseUrl2 + "/batchscheduler/batchinsert";

  //Image configuration
  image_listing = this.baseUrl2 + "/engagement-config/get-tactical-challenges";
  Add_image = this.baseUrl2 + "/engagement-config/tactical-challenges";

  //gap analysis report
  gapAnalysisReport = this.baseUrl2 + "/users/getoutletgapanalysis";

  //Reward Management Module API's
  getPlaceOrderDetails = this.baseUrl2 + "/rewards/get-place-order-deatils";

  updatePendingOrders = this.baseUrl2 + "/rewards/process-pending-orders";

  getOrderList = this.baseUrl2 + "/rewards/get-orders-list";

  updateRejectedOrders = this.baseUrl2 + "/rewards/process-reject-order-list";

  getFailedOrdersList = this.baseUrl2 + "/rewards/get-failed-orders";

  returnFailedUserPoints = this.baseUrl2 + "/rewards/post-return-points-failed-orders";

  rejectFailedUserPoints = this.baseUrl2 + "/rewards/post-reject-failed-orders";

  //Maker-Checker Configuration
  addMakerCheckerConfig = this.baseUrl2 + "/rewards/maker-checker-config";

  listMakerCheckerConfig = this.baseUrl2 + "/rewards/get-maker-checker-config";

  toggleConfigStatus = this.baseUrl2 + "/rewards/change-config-status";
  // carton Insert New //

  generateQRCarton = this.baseUrl2 + "/carton-insert/qr-code-generation-api";
  getCartonBrand = this.baseUrl2 + "/carton-insert/get-products";
  createCartonCampaign = this.baseUrl2 + "/carton-insert/create-carton-campaign";
  editCartonCapmpaign = this.baseUrl2 + "/carton-insert/update-carton-campaign";
  cartonWhiteListUser = this.baseUrl2 + "/carton-insert/whitelist-users";
  cartonUploadTarget = this.baseUrl2 + "/carton-insert/upload-targets"
  cartonCampaignList = this.baseUrl2 + "/carton-insert/campaigns-list";
  CartonCampaignDetail = this.baseUrl2 + "/carton-insert/get-campaigns-details";
  CartonTargetList = this.baseUrl2 + "/carton-insert/get-targets-list";
  CartonWhiteList = this.baseUrl2 + "/carton-insert/get-whitelist-users";
  pointConfigurationList = this.baseUrl2 + "/carton-insert/get-points-structure";
  pointConfigurationUpload = this.baseUrl2 + "/carton-insert/points-structure";
  specialOcceranceList = this.baseUrl2 + "/carton-insert/get-special-points-structure";
  specialOcceranceUpload = this.baseUrl2 + "/carton-insert/special-points-structure";
  cartonQrlist = this.baseUrl2 + "/carton-insert/qr-code-list";
  cartonQrDownload = this.baseUrl2 + "/carton-insert/download-qr-codes";
  cartonBrand = this.baseUrl2 + "/users/getbrands";

  //reports
  liveReport = this.baseUrl2 + "/adminreport/weeklyreports";
  pointsLedgerReport = this.baseUrl2 + "/adminreport/downloadhubadminreport";
  newCsmOnbording = this.baseUrl2 + "/adminreport/csmonboarding";
  outletOnboarding = this.baseUrl2 + "/adminreport/outletonboarding";
  whitelistEngagement = this.baseUrl2 + "/engagement/whitelist-engagement";



  // govardhan jgi api

  // target and achievement
  addTarget = this.baseUrl2 + "/users/target-insert";
  nepaliMonth = this.baseUrl2 + "/users/nepali-months";
  targetPoints = this.baseUrl2 + "/users/get-point-structure";
  targetPointUpload = this.baseUrl2 + "/users/point-structure-import";
  targetAchievementUpload = this.baseUrl2 + "/users/user-achievement-import";
  targetUplaod = this.baseUrl2 + "/users/user-target-import";
  getUserTarget = this.baseUrl2 + "/users/get-user-target";
  getAchievementDetail = this.baseUrl2 + "/users/get-user-achievement";
  getTargets = this.baseUrl2 + "/users/get-target"
  updatePointTarget = this.baseUrl2 + "/users/point-target-update"
  // updateTargetPoints = this.baseUrl2 + "/users/user-target-update"
  updateAchivementPoints = this.baseUrl2 + "/users/user-achievement-update"
  getCitiesList = this.baseUrl2 + "/users/get-city";
  getRegionsList = this.baseUrl2 + "/users/region";
  getChannel = this.baseUrl2 + "/users/get-channel";
  getChannelSegment = this.baseUrl2 + "/users/get-channel-segment";
  getBrand = this.baseUrl2 + "/users/get-brand";

  // engagement
  engagementTypes = this.baseUrl2 + "/engagement/engagementtypes";
  engagementElement = this.baseUrl2 + "/engagement/engagementelementtypes";
  createImageEngagement = this.baseUrl2 + "/engagement/create-engagement";
  engagementList = this.baseUrl2 + "/engagement/list-engagement";
  createContentAndLarning = this.baseUrl2 + "/engagement/create-engagement-content";
  whiteListEngagementUser = this.baseUrl2 + "/engagement/whitelist-engagement";
  engagemetnWhitelistUser = this.baseUrl2 + "/engagement/user-whitelisted";
  deactiveEnagegementUser = this.baseUrl2 + "/engagement/deactivate-whitelist";
  // getEngagementTemplateDetail = this.baseUrl2 + "/users/elementdata";
  uploadInstructionImage = this.baseUrl2 + "/engagement/upload-instruction-image";
  viewEngagementDetail = this.baseUrl2 + "/engagement/view-engagement";
  createEngagementQuiz = this.baseUrl2 + "/engagement/create-engagement-content"
  getAssigned_users = this.baseUrl2 + "/engagement/user-whitelisted"

  // profile Upadate
  profileUpladeExecelUpaload = this.baseUrl2 + "/users/import-profile-data";
  profileChangeData = this.baseUrl2 + "/users/profile-change-data";
  userProfileList = this.baseUrl2 + "/users/profile-change-data-list";
  userProfileStatusChange = this.baseUrl2 + "/users/update-profile-data";
  profileRejectedReason = this.baseUrl2 + "/users/profile-reject-reasons"
  // ***** Reward Panel **** \\
  // getdelPartnerList = this.baseUrl2 + "/delivery-partner/get-partner?";
  changeOrderStatusByForm = this.baseUrl2 + "/ops-panel/bulk-status-update?program_id=1";
  getDownOrderData = this.baseUrl2 + "/ops-panel/download-order-data";
  getRewardDropdown = this.baseUrl2 + "/ops-panel/get-rewards-dropdown";
  disableRewardProduct = this.baseUrl2 + "/rewards/delete-reward-products"

  // ***** Reward Panel **** \\
  getPhysicalOrderList = this.baseUrl2 + "/ops-panel/get-order-listings";
  getStatusTypes = this.baseUrl2 + "/ops-panel/get-order-status";
  getCancelReasonDropDown = this.baseUrl2 + "/ops-panel/get-rejection";
  getdelPartnerList = this.baseUrl2 + "/ops-panel/delivery-partner";
  changeOrderStatusByeExcel = this.baseUrl2 + "/ops-panel/order-statusupload?program_id=1";

  // ************** COMMON MATRIX *****************
  adhocNotificationList = this.baseUrl2 + "/communication-panel/get-adhoc-push-notification-list";
  adhocuploadExcel = this.baseUrl2 + "/communication-panel/import-excel-adhoc-push-notification";
  addhocuploadrolewise = this.baseUrl2 + "/communication-panel/create-adhoc-push-notification-based-on-role";
  addhocFileld = this.baseUrl2 + "/communication-panel/get-select-field-details";
  addhocupdateNotification = this.baseUrl2 + "/communication-panel/update-adhoc-notification";
  moduleListForReminderNoti = this.baseUrl2 + "/communication-panel/get-module-list-reminder?";
  subModuleItemList = this.baseUrl2 + "/communication-panel/get-sub-item-list";
  getRemenderNotification = this.baseUrl2 + "/communication-panel/get-reminder-push-notification-list";
  addRemenderNotification = this.baseUrl2 + "/communication-panel/create-sub-item-reminder-notification-data";
  updateRemenderNotification = this.baseUrl2 + "/communication-panel/update-sub-item-reminder-notification-data";
  fieldList = this.baseUrl2 + "/communication-panel/get-scenario-details";
  pushNotification = this.baseUrl2 + "/communication-panel/get-template-push-notification";
  moduleList = this.baseUrl2 + "/communication-panel/get-module-list";
  sendTempleteNotification = this.baseUrl2 + "/communication-panel/update-template-push-notification";
  getMatrixModulesDetails = this.baseUrl2 + "/communication-panel/get-scenario-detail?";
  triggerAdhocNotification = this.baseUrl2 + "/communication-panel/adhoc-push-notifications?";
  triggerEngageRemNotification = this.baseUrl2 + "/communication-panel/trigger-reminder-push-notification-for-engagement?";
  triggerTargetRemNotification = this.baseUrl2 + "/communication-panel/achievement-remainder-push-notifications?";
  triggerSchemeRemNotification = this.baseUrl2 + "/communication-panel/schemes-remainder-push-notifications?";
  getadHocRoles = this.baseUrl2 + "/communication-panel/get-role"
  disableUserManageStatus = this.baseUrl2 + "/users/outlet-status"
  brandsExcelUpload = this.baseUrl2 + "/brands/upload-brands";
  brandsExcelHeaders = this.baseUrl2 + '/brands/brand-upload-fields';
  ///
  //  / test
  // MASTER SETUP
  portfolioMaster = this.baseUrl2 + "/brands/get-portfolio"
  disableLanguage = this.baseUrl2 + "/users/delete-languages";
  channelSegment = this.baseUrl2 + "/channels/get-channel-segment";
  addChannelSegmet = this.baseUrl2 + "/channels/create-channel-segment"
  editChannelSegment = this.baseUrl2 + "/channels/update-channel-segment"
  disableChannelSegment = this.baseUrl2 + "/channels/delete-channel-segment"
  addPortfolio = this.baseUrl2 + "/brands/create-portfolio"
  disablePortfolio = this.baseUrl2 + "/brands/delete-portfolio"
  editPortfolio = this.baseUrl2 + "/brands/update-portfolio"
  appModules = this.baseUrl2 + "/users/get-module";
  disableAppModules = this.baseUrl2 + "/users/delete-module"
  appBanner = this.baseUrl2 + "/users/get-banner";
  addAppBanner = this.baseUrl2 + "/users/create-banner"
  editAppBanner = this.baseUrl2 + "/users/update-banner"
  disableAppBanner = this.baseUrl2 + "/users/delete-banner"
  getOutlet = this.baseUrl2 + "/users/get-outlet-type"
  addOutlet = this.baseUrl2 + "/users/create-outlet-type"
  editOutlet = this.baseUrl2 + "/users/update-outlet-type"
  disableOUtlet = this.baseUrl2 + "/users/delete-outlet-type"
  configGetBrandCategory = this.baseUrl2 + "/brands/get-brand-categories";
  configAddBrandCategory = this.baseUrl2 + "/brands/create-brand-categories";
  configUpdateBrandCategory = this.baseUrl2 + "/brands/update-brand-categories";
  configDisableBrandCategory = this.baseUrl2 + "/brands/delete-brand-categories";
  getBrandsSku = this.baseUrl2 + "/brands/get-brand-sku-categories";
  addBrandsSku = this.baseUrl2 + "/brands/create-brand-sku-categories";
  editBrandsSku = this.baseUrl2 + "/brands/update-brand-sku-categories";
  disableBrandsSku = this.baseUrl2 + "/brands/delete-brand-sku-categories";
  getPanelTypes = this.baseUrl2 + "/panel/get-panel"
  disablePanelTypes = this.baseUrl2 + "/panel/delete-panel"
  getFaq = this.baseUrl2 + "/banner/get-faq";
  editFaq = this.baseUrl2 + "/banner/update-faq"
  getTermsConditions = this.baseUrl2 + "/banner/get-terms-condition"
  editTermsConditions = this.baseUrl2 + "/banner/update-terms-condition"
  // CALL CENTER
  getEnquiries = this.baseUrl2 + "/call-center/get-enquiries"
  getUserProfiles = this.baseUrl2 + "/call-center/get-user-profile"
  getAccountStatement = this.baseUrl2 + "/call-center/get-account-statement"
  getTargetVsAchievement = this.baseUrl2 + "/call-center/get-target-achivement"
  getcallEngagement = this.baseUrl2 + "/call-center/get-engagement"
  updateEnquiry = this.baseUrl2 + "/call-center/update-enquiries"
  getDashboardUser = this.baseUrl2 + "/panel/dasboard-user"
  addDashboardUser = this.baseUrl2 + "/panel/create-dasboard"
  editDashboardUser = this.baseUrl2 + "/panel/update-dasboard"
  disableDashboardUser = this.baseUrl2 + "/panel/delete-dasboard"
  getImageValidationUser = this.baseUrl2 + "/panel/image-validation-user"
  addImageValidationUser = this.baseUrl2 + "/panel/create-image-validation"
  getCallCenterMaster = this.baseUrl2 + "/panel/call-center-user"
  addCallCenterMaster = this.baseUrl2 + "/panel/create-call-center"
  getRedemptionAuthUser = this.baseUrl2 + "/panel/redemption-user"
  addRedemptionAuthUser = this.baseUrl2 + "/panel/create-redemption-user"
  updateFaq = this.baseUrl2 + "/banner/update-faq";
  addFaq = this.baseUrl2 + "/banner/create-faq";
  disableFaq = this.baseUrl2 + "/banner/delete-faq"
  disableRewardCategories = this.baseUrl2 + "/rewards/delete-reward-category"
  disableRewardSubCategories = this.baseUrl2 + "/rewards/delete-reward-subcategory"
  disableRewardBrands = this.baseUrl2 + "/rewards/delete-reward-brands"
  getDropdownRewardCat = this.baseUrl2 + "/rewards/drop-reward-category"
  getDropdownRewardSubCat = this.baseUrl2 + "/rewards/drop-reward-sub-category"
  getDropdownRewardBrand = this.baseUrl2 + "/rewards/drop-reward-brand"
  getTargetTypes = this.baseUrl2 + "/users/get-target-types"
  addTargetType = this.baseUrl2 + "/users/create-target-types"
  editTargetType = this.baseUrl2 + "/users/update-target-types"
  disableTargetType = this.baseUrl2 + "/users/delete-target-types"
  getDropTargetTypes = this.baseUrl2 + "/users/drop-target-types"
  manualCreditDebit = this.baseUrl2 + "/users/users-mobile"
  addManualCreditDebit = this.baseUrl2 + "/users/manual-points"


  // **Crow Collection
  //point credit
  // createTargets = this.baseUrl2 + "/crown/create-target";
  getcrownTargetList = this.baseUrl2 + "/crown/list-crown-target";
  //Upload and Download Excel
  getExcelHearderList = this.baseUrl2 + "/crown/list-brand";
  uploadPointTarget = this.baseUrl2 + "/crown/captain-point-structure";
  uploadOutletTarget = this.baseUrl2 + "/crown/upload-outlet-target";
  getListPointStrucuture = this.baseUrl2 + "/crown/list-point-structure";
  getUserTragetsList = this.baseUrl2 + "/crown/list-crown-user-target";
  editPoinStuctureValue = this.baseUrl2 + "/crown/edit-point-structure";
  editOutletTargets = this.baseUrl2 + "/crown/edit-outlet-target";
  getImportCaptain = this.baseUrl2 + "/users/import-captain";
  updateCaptainWhitelist = this.baseUrl2 + "/users/update-captain";
  captainWhitelitListing = this.baseUrl2 + "/users/get-captain?";
  getCreateCaptain = this.baseUrl2 + "/users/create-captain";
  getCaptainList = this.baseUrl2 + "/users/get-captain-only?";
  editeCatainName = this.baseUrl2 + "/users/update-captain-only";
  disableCaptainStatus = this.baseUrl2 + "/users/delete-captain-only";
  editCrownTarget = this.baseUrl2 + "/crown/edit-crown-target";

  //** */ auditPannel

  //Audit pendings
  getAuditList = this.baseUrl2 + "/crown/audit-list?status=1";
  //Audit Aproved
  getAuditListProved = this.baseUrl2 + "/crown/audit-list?status=2";
  rejectAudit = this.baseUrl2 + "/crown/reject-audit";
  getParoveAudit = this.baseUrl2 + "/crown/approve-audit";
  pointCalcEngagement = this.baseUrl2 + "/engagement/calculate-points";
  getRejectedReason = this.baseUrl2 + "/crown/list-rejected-reason"
  getCrownZoneList = this.baseUrl2 + "/crown/list-zone";


  // Target and achievements
  
  deleteTarget = this.baseUrl2 + "/loyalty-target/delete-targets";
  regionWiseReports = this.baseUrl2 + '/outlet-reports/export-region-wise-targets';
  targetsVsAchievedReports = this.baseUrl2 + '/outlet-reports/export-target-vs-achieved';
  get_all_the_programs = this.baseUrl2 + '/programs/get-programs?id=';

  filterStatesParams = this.baseUrl2 + "/state/get-state?program_id=1";
  filterCitiesParams = this.baseUrl2 + "/city/get-city?program_id=1";
  downloadTargetFile = this.baseUrl2 + '/loyalty-target/download-target-details'
  getTargetAchivementList = this.baseUrl2 + "/loyalty-target/get-targets";
  downloadExcelForTargets = this.baseUrl2 + "/loyalty-target/get-excel-target-sku?program_id=1";

  createBrandTargets_general = this.baseUrl2 + "/loyalty-target/create-loyalty-general-setting";
  setTarget_points_for_sku = this.baseUrl2 + '/loyalty-target/create-loyalty-points-setting';
  update_targets_for_sku = this.baseUrl2 + '/loyalty-target/update-loyalty-general-setting-with-products?id=';
  getTargetListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-target-general-mapping?target_id=";

  getPointListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-point-general-mapping?target_id=";
  getTargetPointBrands = this.baseUrl2 + "/brands/get-brand";
  getUserTargetPoints = this.baseUrl2 + "/loyalty-target/user-targets-points?target_id=";
  getUserTargets = this.baseUrl2 + "/loyalty-target/get-users-targets?target_id=";
  uploadExcelForTargets = this.baseUrl2 + "/loyalty-target/upload-target-points";
  getTargetListDropDown = this.baseUrl2 + "/target-and-achievement/get-targets";

  configGetBrandsDrop = this.baseUrl2 + "/target-and-achievement/get-brands";
  rejectedRecords = this.baseUrl2 + '/targetand-achievement/mc-account-report'
  voucherCounts = this.baseUrl2 + '/target-and-achievement/voucher-report'
  failedCashback = this.baseUrl2 + '/targetand-achievement/fail-cashfree'
  getArchivement_list = this.baseUrl2 + '/target-and-achievement/get-achievement-import';
  listMakerChecker = this.baseUrl2 + "/target-and-achievement/list-maker-check";
  generateMakerChecker = this.baseUrl2 + "/target-and-achievement/maker-check";
  approveReports = this.baseUrl2 + "/target-and-achievement/upload-points";
  cashBackProcess = this.baseUrl2 + "/target-and-achievement/cashback-process"
  listcashOrders = this.baseUrl2 + "/targetand-achievement/list-orderitem"
  approveReject = this.baseUrl2 + "/targetand-achievement/approve-cash"
  getDownloadReport = this.baseUrl2 + "/loyalty-target/get-excel-achievement?program_id=1&";
  checker_maker_bonous_points = this.baseUrl2 + '/loyalty-target/get-bonous?';
  modifyCheckerAndMakerPoints = this.baseUrl2 + "/loyalty-target/maker-checker-transactions";
  downloadSchemeExcel = this.baseUrl2 + '/scheme/scheme-excel-achievement?achivement_id='
  uploadNewSchemeMakerData = this.baseUrl2 + '/scheme/scheme-maker-checker-transactions'
  downloadAchievementExcel = this.baseUrl2 + '/loyalty-target/download-achievement-target?program_id=1'
  getTargetList=this.baseUrl2+"/target-and-achievement/get-targets";
  getTargetOnID =this.baseUrl2+"/target-and-achievement/get-target-details";
  getTragetList=this.baseUrl2+"/targetand-achievement/get-point-config-template?";
  getChannelList=this.baseUrl2+"/targetand-achievement/get-channel";
  createPointConfig=this.baseUrl2+"/targetand-achievement/create-point-config-template"
  createTargets=this.baseUrl2+"/target-and-achievement/create-target";
  editPoinStructure=this.baseUrl2+"/target-and-achievement/point-structure";
  uploadTargetsDetails=this.baseUrl2+"/target-and-achievement/target-import?program_id=1";
  uploadArchivements_excelUpload = this.baseUrl2 + "/target-and-achievement/achievement-import?program_id=1";
  getTargetData=this.baseUrl2+"/target-and-achievement/loyalty-target-users";
  getStatesAbinBev = this.baseUrl2 + "/userdetails/states"
  getEngagementTemplateDetail = this.baseUrl2 + "/target-and-achievement/elementdata";
  getCityAbinBev = this.baseUrl2 + '/userdetails/cities'
  getPointsList = this.baseUrl2  + "/target-and-achievement/target-points"
  updateTargetPoints = this.baseUrl2 + "/target-and-achievement/update-target-points"
  updatetarget = this.baseUrl2 + "/target-and-achievement/update-loyalty-target"
  getTargetType = this.baseUrl2 + "/target-and-achievement/get-targettype"

  // Campaign Whitelist

  createCampaign = this.baseUrl2 + "/userdetails/campaign"
  whitelistCampaign = this.baseUrl2 + "/userdetails/campaign-whitelist"
  getCampainWhitelist_outlet = this.baseUrl2 + "/userdetails/get-campaign-whitelist-outlets"
  campaignDisable = this.baseUrl2 + "/userdetails/campaign-disable"
  campaignList = this.baseUrl2 + "/hubadmin/modulename"
  // This api is to use in campaign  
  getListofAllCampaign = this.baseUrl2 + '/userdetails/get-campaign-list'
  getCampaignOnid = this.baseUrl2 + "/userdetails/get-campaign-list"
  // Tis api is to use every where other than campaign module 
  getAllListOfCampaign = this.baseUrl2 + "/userdetails/get-all-campaign"
  // se-enroll
  enrollSaleExecutive = this.baseUrl2 + "/enroll/se-enroll"
  getEnrolledSe = this.baseUrl2 + "/userdetails/get-se-barstaffdetails?id=1"

// outletEnroll
enrollOutlet = this.baseUrl2 + "/enroll/outlet-enroll"
getEnrolledOutlets = this.baseUrl2 + "/userdetails/get-se-barstaffdetails?id=3"

// barStaffOnboarding
barstaffOnboarding = this.baseUrl2 + "/enroll/barstaffonboarding"
getBarStaffList = this.baseUrl2 + "/userdetails/get-se-barstaffdetails?id=2"

// userOnboard
userOnboard = this.baseUrl2 + "/enroll/useronboard"
getUserOnboardlist = this.baseUrl2 + "/userdetails/get-se-barstaffdetails?id=4"

// Settings
getSettings = this.baseUrl2 + "/setting/get-settings"
getRoleID = this.baseUrl2 + "/userdetails/getroleid"
createSettings = this.baseUrl2 + "/setting/settings-insert" 
updateSettings = this.baseUrl2 + "/setting/settings-update" 
deleteSettings = this.baseUrl2 + "/setting/settings-delete" 
createFAQs = this.baseUrl2 + "/setting/create-faq" 
getFAQs = this.baseUrl2 + "/setting/read-faq"
updateFaqs = this.baseUrl2 + "/setting/update-faq"
deleteFaqs = this.baseUrl2 + "/setting/delete-faq"

// Agent panel Enrollment
get_user_EnrollList = this.baseUrl2 + "/agent/get-user-details";
downloadUserExcel = this.baseUrl2 + "/users/download-user-excel?program_id=1";
approveRejectEnrollNew = this.baseUrl2 + "/users/approve-reject-enrollment";
bulkApprove = this.baseUrl2 + '/visit-tracker/bulk-verification';
approveRejectEnrollment = this.baseUrl2 + "/visit-tracker/approve-reject-enrollment?program_id=1";

getReasons = this.baseUrl2 + '/visit-tracker/rejection-reasons?program_id=1';
getEnrollments = this.baseUrl2 + '/visit-tracker/get-enrollments?program_id=1';
updateAddress = this.baseUrl2 + '/visit-tracker/update-user-address';

// User management
get_user_details = this.baseUrl2 + "/userdetails/get-all-users"

// image_to_url
image_to_url = this.baseUrl2 + "/userdetails/image-to-url"

// moduleName
moduleName = this.baseUrl2  + "/hubadmin/modulename"

// User-Aoc
usersAocEmptyExcel = this.baseUrl2 + "/hubadmin/headers-excel"
userExcelUploadNew = this.baseUrl2 + "/enroll/enrollment"

// engagement(disable engagment)
disableEngagement = this.baseUrl2 + "/engagement/disable-engagement"

// LeaderBoard
leaderBoardList = this.baseUrl2  + "/hubadmin/get-leaderboard"

// Brand HUb

getBrandhubTypes = this.baseUrl2 + "/brand-hub/get-brand-hub-types"
getBrandhub = this.baseUrl2 + "/brand-hub/get-brand-hubs"
createBrandhub = this.baseUrl2 + "/brand-hub/create-brand-hub"
updateBrandhub = this.baseUrl2 + "/brand-hub/update-brand-hub"
deleteBrandhub = this.baseUrl2 + "/brand-hub/delete-brand-hub"
getBrandHubWhiltestedUser = this.baseUrl2 + "/brand-hub/list-brand-hub-whitelist-users"
whitelistbrandHUsers = this.baseUrl2 + "/brand-hub/brand-hubwhitelist"

// Rewards

getRewardlist = this.baseUrl2 + "/reward/get-reward"
disableReward =  this.baseUrl2 + "/reward/disablereward"
whitelistReward = this.baseUrl2 + "/reward/rewardwhitelist"
createReward = this.baseUrl2 + "/reward/createreward"

// Mystery Shoppper

createQyuestionGroup = this.baseUrl2 + "/mystery/createquestiongroup"
getQyuestionGroup = this.baseUrl2 + "/mystery/readquestiongroup"
getQuestionType = this.baseUrl2 + "/mystery/readquestiontype"
createQyuestion = this.baseUrl2 + "/mystery/createquestion"
getQuestion = this.baseUrl2 + "/mystery/readquestions"
createOptions = this.baseUrl2 + "/mystery/createoption"
updateOptions = this.baseUrl2 + "/mystery/createoption"
createTemplate = this.baseUrl2 + "/mystery/createtemplate"
getTemplate = this.baseUrl2 + "/mystery/readtemplate"
questionTempplateMapping = this.baseUrl2 + "/mystery/templatequestionmapping"

disableButtonArray = this.baseUrl2 + "/mystery/templatequestionbytempid"
listOfMappedQuestion =  this.baseUrl2 + "/mystery/readtemplatequestionmapping"

createAudit = this.baseUrl2 + "/mystery/createaudit"
readAudit = this.baseUrl2 + "/mystery/readaudit"

readUsers = this.baseUrl2 + "/mystery/get-audit-users"
updateUsers = this.baseUrl2 + "/mystery/update-audit-user-status"

// Role Change In userMangement

userRoleChange = this.baseUrl2 + "/user-role/user-rolechange"
userMobileUpdation = this.baseUrl2 + "/users/user-updation"


// Banner
whitlelistBannerToUser = this.baseUrl2 + "/users/bannerwhitelist"

}
