export const quizTypes: any = [
  {
    id: 1,
    name: "Quiz",
  },
  {
    id: 2,
    name: "Image",
  },
  {
    id: 3,
    name: "Information task",
  },
];
export const subQuizTypes: any = [
  {
    name: "Text",
    id: 1,
  },
  // {
  //   name: "Image",
  //   id: 2,
  // },
];
export const colorCodes: any = [
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
];
export const month_list: any = [
  {
    "abbreviation": "Jan",
    "name": "January",
    id: '01'
  },
  {
    "abbreviation": "Feb",
    "name": "February",
    id: '02'
  },
  {
    "abbreviation": "Mar",
    "name": "March",
    id: '03'
  },
  {
    "abbreviation": "Apr",
    "name": "April",
    id: '04'
  },
  {
    "abbreviation": "May",
    "name": "May",
    id: '05'
  },
  {
    "abbreviation": "Jun",
    "name": "June",
    id: '06'
  },
  {
    "abbreviation": "Jul",
    "name": "July",
    id: '07'
  },
  {
    "abbreviation": "Aug",
    "name": "August",
    id: '08'
  },
  {
    "abbreviation": "Sep",
    "name": "September",
    id: '09'
  },
  {
    "abbreviation": "Oct",
    "name": "October",
    id: '10'
  },
  {
    "abbreviation": "Nov",
    "name": "November",
    id: '11'
  },
  {
    "abbreviation": "Dec",
    "name": "December",
    id: '12'
  }
]
export const taskIntroTemplate: any = {
  template: `
    <div class="row">
    <div class="col-12" text-center>
        <p class="P-titlepic">Answer 6 simple Bud4Trade questions to win</p>

        <div class="main_i">
            <div class="coin_image"><img class="c_image" src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png" style="" /></div>

            <div class="coin_i">
                <p class="P-titlePoints">13 COINS</p>
            </div>
        </div>
    </div>
  </div>

  <div class="row border" no-padding>
    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz1.png" /></div>
        </div>
    </div>

    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz2.png" /></div>
        </div>
    </div>
  </div>

  <div class="row margin_i" padding>
    <div class="row_inst" col-12 no-padding text-center>INSTRUCTIONS</div>
  </div>

  <div class="row margin_l">
    <ol>
        <li class="inst">Click on Start Quiz.</li>

        <li class="inst">Select the right answers for all questions.</li>

        <li class="inst">Earn assured points only if all questions are answered correctly.</li>
    </ol>
  </div>
  `,
};
export const REWARDS_BRAND_EXCEL: any[] = ["Brand Name", "Brand Code", "Base Image"];
export const REWARDS_SUB_CATEGORIES_EXCEL: any[] = ["Sub Category Name", "Description", "Category ID", "Base Image"];
export const campaignDetails: any = {
  campaign_name: "",
  engagement_type: "",
  title: "",
  subtitle: "",
  // activity_id:'',
  instruction: "",
  start_date: "",
  end_date: "",
  frequency: "",
  // user_role_id:'',
  help: "no data",
  quiz_points_criteria: "",
};
export const searchUsers: any = {
  filter_search_geography: [],
  filter_search_channel: [],
  filter_search_region: [],
  filter_search_userrole: [],
  filter_search_state: []
};
export const updateEngagementDetails: any = {
  campaign_id: 0,
  task_type: 0,
  subtask_type: 0,
  success_points: 0,
  user_role_id: 0,
  task_question_id: 0,
  subtask_question_id: null,
  program_id: 1,
  task_frequency: 0,
  is_information: 0,
  campaign_activities_config_id: 0,
  quiz_points_criteria: 0,
  starttimeslot: null,
  endtimeslot: null,
  created_date: null,
  updated_date: null,
  has_subtask: false,
  description: null,
  instruction: "",
  title: "",
  subtitle: "",
  help: "",
  success_msg: null,
  failure_msg: null,
};
export const createTextQuiz: any = {
  campaign_id: 0,
  campaign_activities_config_id: 0,
  question: "",
  question_type: 2,
  quiz_type: 0,
  option1: "",
  option2: "",
  option3: "",
  option4: "",
  option5: null,
  no_of_points: 0,
  time_in_seconds: null,
  display_date: null,
  display_time: null,
  created_date: "2020-04-12 10:30:46",
  updated_date: "2020-04-12 10:30:46",
  answer: "option3",
  is_subtask: null,
  program_id: 1,
  is_answer_check: false,
};
export const engagementRewardPoints: any = {
  rank: "Winner",
  points: "",
  isAdd: false,
  isRemove: false,
};
export const weelOfFortune: any = {
  ispointsOrReward: 2,
  products: [
    {
      id: 1,
      name: "product A",
    },
  ],
  divisions: [
    {
      id: 1,
      name: "Wheighttage Percentage",
    },
  ],
  dinomination: "",
  probability: "",
  qty: "",
  points: "",
  isAdd: false,
};
export const weelOfFortuneConfig: any = {
  button_text: "",
  frequency: "",
  max_winners_day: "",
  max_winners_engagement: "",
};
export const creatActivity: any = {
  campaign_id: 1,
  campaign_name: "",
  activity_title: "",
  trigger_name: "",
  activity_type: null,
  points: null,
  frequency_id: 1,
  frequency_name: "",
  activity_per_day: null,
  days: null,
  set_trigger: 0,
  program_id: 1,
  status: 1,
  start_date: null,
  end_date: null,
  created_date: null,
  updated_date: null,
  activity_desc: null,
  reward: null,
  success_msg: "",
  success_notify: "",
  success_sms: " ",
  fail_msg: "",
  fail_notify: null,
  fail_sms: "",
  instruction: "",
  task_id: null,
  id: null,
};

export const pointsDetails: any = {
  task_id: null,
  reward_type: 1,
  campaign_id: null,
  task_rank_based_reward: [],
  assured_type: 1,
  task_assured_fixed_point: { total_point: null },
  wof_title: "Wof Title",
  wof_configurations: [],
  max_winners: null,
  spin_btn_text: "Spin the wheel",
  frequency: 1,
  wof_configSettings: {
    denomination_text: "",
    weightage_percentage: null,
    wof_reward_type: 2,
    reward: null,
    color: "",
  },
};
export class TargetAchievementModel {
  denomination_text: string;
  weightage_percentage: number;
  wof_reward_type: number;
  reward: number;
  color: string;
  isAdd: boolean;
}
/**
 * Content&Learning
 */
export const createCourse: any = {
  program_id: 1,
  course_description: "",
  course_ana_title: null,
  video_url: null,
  course_type: 0,
  points: null,
  frequency_id: "",
  start_date: "",
  expiry_date: "",
  pre_expiry_date: "",
  quiz_configured: "",
  quiz_points_criteria: "",
  realted_to_engagement: "",
  module_enabled: null,
  module_expired: null,
  course_title: "",
  course_image: null,
  course_content: null,
  instruction_en: null,
};

export const TARGET_EXCEL: any[] = [
  "Outlet Code",
  "Target Month",
  "Target Year",
];

export const SALES_EXCEL: any[] = [
  "Outlet Code",
  "Outlet Name",
  "Mobile No.",
  "Category",
  "Target Qty",
  "Achievement Qty",
];

export const SALES_PRODUCT_EXCEL: any[] = [
  "Outlet Code",
  "Outlet Name",
  "Mobile No.",
  "Product Code",
  "Product Name",
  "Target Qty",
  "Achievement Qty",
];
export const WHITELISTING_EXCEL: any[] = [
"CSM Number",
"Outlet Code",
];

export const POINT_STRUCTURE_EXCEL: any[] = [
  "Outlet Code",
  "Product",
  "Points",
];

export const USER_EXCEL: any[] = [
  "User Phone Number",
  "User Role",
  "Region",
  "Reporting User Number",
  "Geography",
  "Channel Type",
  "Channel",
  "State",
  "Outlet Code",
  "Outlet Name",
  "Outlet Email",
  "Name",
  "Licence No",
  "Address",
  "City",
  "Pincode",
  "Alternate Contact Number",
  "Employee Code",
  "Distributors",
];

export const MYSTERY_USER_EXCEL: any[] = [
  "User Phone Number",
  "Region",
  "Geography",
  "Email",
  "Name",
  "Address",
  "Pincode",
  "Alternate Contact Number",
];

export const MYSTERY_QUESTION_BANK: any[] = [
  "Question Type",
  "Question",
  "Options",
  "Answer",
  "Points"
];

export const sampleInstructionTemplateDetails: any = {
  title: "Answer 6 simple questions to win",
  points: "13",
  coin:
    "https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png",
  wrong_banner: "",
  right_banner: "",
  intruction: [
    { text: "Click on Start Quiz." },
    { text: "Select the right answers for all questions." },
    {
      text: "Earn assured points only if all questions are answered correctly.",
    },
  ],
};

export const createQuizContentAndLearning: any = {
  question: "",
  question_hi: "",
  course_id: 2,
  quiz_type: "",
  options: [
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
  ],
  options_hi: [
    {
      option_hi: "fgdf",
      is_right_option: 0,
      id: -1,
    },
    {
      option_hi: "fgdf",
      is_right_option: 0,
      id: -1,
    },
  ],
  points: 0,
  program_id: 1,
  answer: "",
};

export const courseContent: any = [
  {
    type: "img",
    data: "",
  },
  {
    type: "text",
    data: "",
  },
];
export const brandExcelHeaders: any = [
  "product_name",
  "sku_name",
  "product_image",
  "price",
  "points",
  "treshold_value",
  "product_description",
  "category_name",
  "category_image",
  "category_description",
  "sub_category",
  "sub_category_image",
  "is_loyalty",
  "variant",
  "variant_value",
  "stock",
  "distributaors",
  "unit",
  "discount_percentage",
  "offer_price",
  "min_buy_quantity",
  "max_buy_quantity",
  "min_offer_quantity",
  "max_offer_quantity ",
];

export const STATE_EXCEL_HEADER: any[] = ["States"];

export const GEOGRAPHY_EXCEL_HEADER: any[] = ["Geography"];

export const REGION_EXCEL_HEADER: any[] = ["Regions"];

export const CHANNEL_EXCEL_HEADER: any[] = ["Channels"];

export const CITY_EXCEL_HEADER: any[] = ["Cities"];

export const STATE_UPLOAD_HEADER: any[] = [
  "State Name",
  "Region Name",
  "State Code",
];

export const REGION_UPLOAD_HEADER: any[] = ["Region Name"];

export const CITY_UPLOAD_HEADER: any[] = ["City Name", "State Name"];

export const LOYALTY_PRODUCT_HEADER: any[] = [
  "Brand Name",
  "Brand Category Name",
  "Brand Short Name",
];

export const USER_PONITS_HEADER: any[] = [
  "Outlet Code",
  "Module",
  "Description",
  "Points Type",
  "Points"
];

export const USER_PONITS_HEADER_KINGSERVICE_CATEGORY: any[] = [
  "Outlet Code",
  "Module",
  "Description",
  "Points Type",
  "Points",
  "Date",
  "Category"
];

export const USER_PONITS_HEADER_KINGSERVICE_SKU: any[] = [
  "Outlet Code",
  "Module",
  "Description",
  "Points Type",
  "Points",
  "Date",
  "SKU"
];

export const USER_ASSIGNMENT_HEADER: any[] = [
  "Outlet Code"
];
export const PENDING_ORDER_DETAIL_HEADER : any[]=[
  "Outlet Code",
  "Name",
  "Number",
  "Order ID",
  "Order Detail ID",
  "Product Name",
  "Quantity",
  "Denomination",
  "Points Burnt",
  "Ordered Date",
]

export const MYSTERY_USER_ASSIGNMENT_HEADER: any[] = [
  "Mystery Shopper",
  "Outlet Code"
];

export const USER_PHONE_HEADER: any[] = [
  "Outlet Code",
  "Phone"
];

export const Account_Login_Report: any[] = [
  "User Type",
  "Outlet/M1/TMM Name",
  "User Profile Name",
  "Device",
  "IP Address",
  "Logged In Mobile Number",
  "Region",
  "State",
  "Login Via (OTP/Password)",
  "Login Date & Time",
  "Login Status (Success/Failed)"
];

export const POINT_TRANSACTION_REPORT: any[] = [
  "Osmosys Code",
  "Outlet Name",
  "Channel",
  "CSM Mobile Number",
  "Region",
  "State",
  "M1 Name",
  "M2 Name",
  "M3 Name",
  "Transaction ID",
  "Transaction Type",
  "Descriptions",
  "Transaction Month",
  "Transaction Date",
  "Points Earned",
  "Points Burned"
]

export const REDEMPTION_Report: any[] = [
  "Outlet Name",
  "Classification",
  "CSM Mobile Number",
  "Region",
  "State",
  "Order ID",
  "Redemption Date",
  "Product/Voucher Name",
  "QTY",
  "Product/Voucher Value",
  "Points Redeemed"
];


export const CSM_Mobile_Number_Change_Log_Report: any[] = [
  "Osmosys Codes",
  "Change Request",
  "Previous Record",
  "Changed Record",
  "Changed Date"
];


export const Enrollement_Detailed_Report: any[] = [
  "Osmosys Code",
  "Outlet Name",
  "Enrolled Date",
  "Channel",
  "Region",
  "State",
  "Total Points Earned",
  "Total Points Redeemed",
  "Total Points Balance",
  "CSM Name",
  "CSM Mobile Number",
  "M1 Name",
  "M1 Mobile Number",
  "M2 Name",
  "M2 Mobile Number",
  "M3 Name",
  "M3 Mobile Number",
  "TMM Name",
  "TMM Mobile Number",
  "Enrollment Status (Active/Inactive)"
];

export const REPORTS: any[] = [
  { Id: 1, name: "Reports", Parent_Id: 0, Status_Bit: 0 },
  { Id: 2, name: "Daily Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 3, name: "Bud4Trade", Parent_Id: 2, Parent_Name: "Daily Report", Status_Bit: 0 },
  { Id: 4, name: "Mystery Shopper", Parent_Id: 2, Parent_Name: "Daily Report", Status_Bit: 0 },
  { Id: 5, name: "Monthly Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 6, name: "Live Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 7, name: "Firebase Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 8, name: "Uplift Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 1 },
  { Id: 9, name: "Leader-Board Report", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 1 },
  { Id: 10, name: "Other Report", Parent_Id: 0, Parent_Name: "Reports", Status_Bit: 1 },
  { Id: 11, name: "Redemption Report", Parent_Id: 6, Parent_Name: "Live Report", Status_Bit: 0 },
  { Id: 12, name: "Target & Achievement Report", Parent_Id: 5, Parent_Name: "Monthly Report", Status_Bit: 0 },
  // { Id: 13, name: "Compiled State Wise Historical Data", Parent_Id: 5, Parent_Name: "Monthly Report", Status_Bit: 0 },
  { Id: 14, name: "End of Month - Final Engagement Report", Parent_Id: 5, Parent_Name: "Monthly Report", Status_Bit: 0 },
  { Id: 15, name: "Live Points Report", Parent_Id: 6, Parent_Name: "Live Report", Status_Bit: 0 },


  { Id: 16, name: "DAU Time Spent Report", Parent_Id: 7, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 17, name: "Engagement Time Spent Report", Parent_Id: 7, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 18, name: "MAU Time Spent Report", Parent_Id: 7, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 19, name: "Module Time Spent", Parent_Id: 7, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 20, name: "Uplift", Parent_Id: 8, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 21, name: "Leader-Board", Parent_Id: 9, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 22, name: "Hub Admin Reports", Parent_Id: 1, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 23, name: "Account Login Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 24, name: "User Changes Log Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 25, name: "Enrollement Detailed Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 26, name: "Point Transaction Detailed Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 27, name: "Engagement Status", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 28, name: "Redemption Detailed Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 29, name: "Scheme Time Spent", Parent_Id: 7, Parent_Name: "Firebase Report", Status_Bit: 0 },
  { Id: 30, name: "Points Account Statement", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 31, name: "Gap Analysis Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0},
  { Id: 32, name: "Carton Insert Report", Parent_Id: 6, Parent_Name: "Live Report", Status_Bit: 0 },
  { Id: 33, name: "Outlet Master Report", Parent_Id: 6, Parent_Name: "Live Report", Status_Bit: 0 },
  { Id: 34, name: "Account Statement Master Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 35, name: "OTP SMS Consumption", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },
  { Id: 36, name: "User Login History Report", Parent_Id: 22, Parent_Name: "Reports", Status_Bit: 0 },



];

export const REPORT_MESSAGE: any = {
  message: "Your Report request has been received. We will get back to with Report Download Link."
}

export const MONTH: any[] = [
  { id: '1', name: 'Janaury' },
  { id: '2', name: 'February' },
  { id: '3', name: 'March' },
  { id: '4', name: 'April' },
  { id: '5', name: 'May' },
  { id: '6', name: 'June' },
  { id: '7', name: 'July' },
  { id: '8', name: 'August' },
  { id: '9', name: 'September' },
  { id: '10', name: 'October' },
  { id: '11', name: 'November' },
  { id: '12', name: 'December' }
];


export const REDEMPTION_MASTER: any[] = [
  "USER ID",
  "Name",
  "Mobile",
  "Outlet Code",
  "Outlet Name",
  "Address",
  "State",
  "Created Date",
  "Status",
  "Order Ref No.",
  "Product Name",
  "Denomination",
  "Offer Code",
  "Offer Code Pin",
  "Response OrderID",
  "Redeem Points"
];

export const TARGET_AND_ACHIVEMENTS: any[] = [
  "Outlet Code",
  "mobile number",
  "Corona",
  "Budweiser",
  "Warsteiner",
  "Warsteiner",
  "Magnum",
  "Namaste Classic",
  "Namaste Premium",
  "Foster Strong",
  "Hatti",
  "Namaste Strong",
  "Mountain Ice Strong",
  "Black Oak",
  "Blue Oak",
  "Crown",
  "Golden Oak","Master Selection",
  "Royal Treasure",
  "Red Russian",
  "Ruslan Vodka",
  "Ruslan Vodka Gold",
  "Ruslan Vodka Ultra",
  "Swan",
  "Blue Diamond",
  "Himalayan Aila",
  "Ultimate Gin",
  "Grand Master Rum",
  "Himalayan Brandy",
  "Kala Pathar"
];

export const TARGET_POINT_UPLOAD: any[] = [
  "Corona",
  "Budweiser",
  "Warsteiner",
  "Warsteiner",
  "Magnum",
  "Namaste Classic",
  "Namaste Premium",
  "Foster Strong",
  "Hatti",
  "Namaste Strong",
  "Mountain Ice Strong",
  "Black Oak",
  "Blue Oak",
  "Crown",
  "Golden Oak","Master Selection",
  "Royal Treasure",
  "Red Russian",
  "Ruslan Vodka",
  "Ruslan Vodka Gold",
  "Ruslan Vodka Ultra",
  "Swan",
  "Blue Diamond",
  "Himalayan Aila",
  "Ultimate Gin",
  "Grand Master Rum",
  "Himalayan Brandy",
  "Kala Pathar"
];


export const LIVEPOINT: any[] = [
  "SN",
  "Osmosys Code",
  "Outlet Name",
  "State",
  "Mobile Number",
  "Earned Points",
  "Redeemed Points",
  "Unredeemed (LIVE) Points"
  // "CSM Status"
];


export const FIRE_TIME_SPENT: any[] = [
  "Sno.",
  "Date",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Time Spent (In Seconds)"
];

export const FIRE_ENAGEMENT_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Engagement Name",
  "Time Spent (In Seconds"
];

export const FIRE_MAU_TIME_SPENT: any[] = [
  "Sno.",
  "Month/Year",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Avg. Time Spent (In Seconds)"
];

export const FIRE_MODULE_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Time Spent (In Seconds)"
];

export const FIRE_SCHEME_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Scheme Name",
  "Date Clicked"
];


export const GAP_EXCEL_TEMPLATE: any[] = [
  "Osmosys Code",
  "CSM Number",
  "State",
];

export const GAP_EXCEL: any[] = [
  "Osmosys Code",
  "CSM Number",
  "State",
  "Bud4Trade Status",
  "ABINBEV Database",
  "GAP Analysis",
  "GAPS"
];


export const ACHIVEMENTS: any[] = [
  "S.No.",
  "Osmosys Code",
  "Outlet Name",
  "TSE",
  "ASM",
  "State",
  "Channel",
  "Name of CSM",
  "MobileNo.",
  "Haywards/Knockout",
  "Beck's Ice",
  "Budweiser",
  "Magnum",
  "Corona/Hoegaarden",
  "Seven Rivers",
  "Total Target",
  "Haywards/Knockout",
  "Beck's Ice",
  "Budweiser",
  "Magnum",
  "Corona/Hoegaarden'",
  "Seven Rivers",
  "Total Achievement",
  "Haywards/Knockout",
  "Beck's Ice",
  "Budweiser",
  "Magnum",
  "Corona/Hoegaarden'",
  "Seven Rivers",
  "Total Points"
];


export const WHITELISTING_REPORT_EXCEL: any[] = ['Outlet Code', 'Outlet Name', 'King Service','Content & Learning',	'Weekly Challenges', 'Branding', 'Cooler','Region','State Name'];

export const quarter_month_list: any = [
  {
    "id": 1,
    "name": "Q1",
    "quarterly_bonus_percentage": 0.5,
    "monthly_bonus_percentage": 0.25,
    "months": [
      {
        "id": "01",
        "name": "January"
      },
      {
        "id": "02",
        "name": "February"
      },
      {
        "id": "03",
        "name": "March"
      }
    ]
  },
  {
    "id": 2,
    "name": "Q2",
    "quarterly_bonus_percentage": 0.5,
    "monthly_bonus_percentage": 0.25,
    "months": [
      {
        "id": "04",
        "name": "April"
      },
      {
        "id": "05",
        "name": "May"
      },
      {
        "id": "06",
        "name": "June"
      }
    ]
  },
  {
    "id": 3,
    "name": "Q3",
    "quarterly_bonus_percentage": 0.5,
    "monthly_bonus_percentage": 0.25,
    "months": [
      {
        "id": "07",
        "name": "July"
      },
      {
        "id": "08",
        "name": "August"
      },
      {
        "id": "09",
        "name": "September"
      }
    ]
  },
  {
    "id": 4,
    "name": "Q4",
    "quarterly_bonus_percentage": 0.5,
    "monthly_bonus_percentage": 0.25,
    "months": [
      {
        "id": "10",
        "name": "October"
      },
      {
        "id": "11",
        "name": "November"
      },
      {
        "id": "12",
        "name": "December"
      }
    ]
  }
]
export const regularExpression: any = {
  email: "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
  alphanumeric: '/^[0-9a-zA-Z]+$/',
  noSpecialCharector: '^[0-9a-zA-Z. \b]+$',
  latAndLang: '/^[0-9]*\.?[0-9]*$/',
  onlyNumAndSpecialChr: '([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))'
}

export const add_targets_for_brands: any = {
  program_id: 1,
  user_role_id: [],
  channel_id: [],
  region_id: [],
  state_id: [],
  city_id: [],
  target_id: null,
  is_generic: 0,
  generic_target: 0,
  loyalty_target_product_wise: []
}
export const TARGET_ACHIEVEMENT_EXCEL: any[] = [
  "Outlet Code",
  "State Name",
  "Brand Name",
  "Sales Quantity",
  "Month",
  "Year"
];

export const OUTLET_ENROLL_HEADER:any[]=[
  'Outlet Code',
  'Outlet Name',
  'Address',
  'Se Name',
  'Se Mobile',
  'State Name',
  'City Name'
]

