import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiselect-v2',
  templateUrl: './multiselect-v2.component.html',
  styleUrls: ['./multiselect-v2.component.scss']
})
export class MultiselectV2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
